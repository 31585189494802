.carousel {
    width: 1000px;
    height: 380px;
    margin: auto;
    
}
.carousel-control-prev{
    height: 300px;
}
.carousel-control-next{
    height: 300px;
}
.showcase video {
    border-radius: 15px;
    box-shadow: 1px solid white;
}
@media screen and (max-width: 1030px) {
    /* Your CSS rules for screens with a minimum width of 1030px */
    /* For example: */
    .carousel {
        width: 550px;
    }
}
@media screen and (max-width: 559px) {
    /* Your CSS rules for screens with a minimum width of 1030px */
    /* For example: */
    .carousel {
        width: 340px;
    }
}


