.contactUs-container .showcase {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.contactUs-container .toggle {
    background: url(https://i.ibb.co/HrfVRcx/menu.png);
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
}

.contactUs-container .toggle.active {
    background: url(https://i.ibb.co/rt3HybH/close.png);
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
}

.maps-holder {
    width: 50%;
    height: 100%;
}

.info-holder {
    border-radius: 30px;
    width: 80%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
}

.contact-form {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 65px;
    justify-content: stretch;
    gap: 10px;
    background-color: darkgray;
    color: white;
}

.text-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.about-content {

    width: 100%;
    height: 100vh;
    color: white;
    background: url('../images/cont.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.part1,
.part2 {
    font-size: 90px;
    font-weight: bold;
}

.part2 {
    color: #aaff01;
}


.data {
    border-bottom: 1px solid rgba(0, 0, 0, 0.104);
    font-size: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 0;
    gap: 20px;
    font-weight: 300;
    justify-content: flex-start;
}

.text-us {
    width: 100%;
    font-size: 12px;
    opacity: 0.7;
}

.iconSize {
    width: 25px;
}





.about-content .text-holder{
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.about-content p{
    text-align: center;
    width: 100%;

}

@media (max-width: 991px) {
    .part1,
.part2 {
    font-size: 50px;
    font-weight: bold;
}
    .about-content .desc{
        font-size: 20px ;
        margin-bottom: 20px;
    }
    .info-holder {
        flex-direction: column;
    }

    .contact-form {
        width: 100%;
    }

    .maps-holder {
        width: 100%;
    }

    .text-holder {
        text-align: center;
    }

    

    .about-content *{
        width: 80%;
    }

   
}