@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
}

header {
	width: 100%;
	padding: 20px 40px;
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: black;
}

header .logo {
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
}

.toggle {
	position: relative;
	width: 35px;
	height: 35px;
	background: url('../images/path2.png');
	background-repeat: no-repeat;
	background-size: 30px;
	background-position: center;
	cursor: pointer;
}

.toggle.active {
	background: url('../images/path3.png');
	background-repeat: no-repeat;
	background-size: 30px;
	background-position: center;
	cursor: pointer;
}

.showcase {
	/* position: absolute; */
	right: 0;
	width: 100%;
	min-height: 100vh;
	display: flex;
	/* justify-content: space-between; */
	/* gap: 150px; */
	align-items: center;
	flex-direction: column;
	background: #111;
	transition: 0.5s;
	z-index: 2;
	position: relative;
}

.showcase.active {
	right: 300px;
}

.showcase video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.8;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #29335c;
	mix-blend-mode: overlay;
}

.text {
	
	z-index: 10;

	padding: 0 40px;
}
.text-container{
	margin: 50px 0;
	height: 80vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.text h2 {
	font-family: "Cairo", sans-serif;
	font-size: 3em;
	font-weight: 800;
	color: #fff;
	text-transform: uppercase;
}

.text h3 {
	font-family: "Cairo", sans-serif;
	font-size: 2em;
	font-weight: 700;
	color: #fff;
	line-height: 1em;
	text-transform: uppercase;
}

.text p {
	font-size: 1.1em;
	color: #fff;
	margin: 20px 0;
	font-weight: 400;
	max-width: 700px;
}

.text a {
	display: inline-block;
	font-size: 1em;
	background: #fff;
	padding: 10px 30px;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	margin-top: 10px;
	color: #111;
	letter-spacing: 2px;
	transition: 0.2s;
}

.text a:hover {
	letter-spacing: 6px;
}

.social {
	z-index: 10;
	padding: 2px 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background: black;
	margin-bottom: 0 !important;
	
}

.social li {
	list-style: none;
}

.social li a {
	display: inline-block;
	margin-right: 20px;
	filter: invert(1);
	transform: scale(0.5);
	transition: 0.5s;
}

.social li a:hover {
	transform: scale(0.5) translateY(-15px);
}

.menu {
	position: absolute;
	top: 0;
	right: 0;
	width: 300px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menu ul {
	position: relative;
	font-family: "Cairo", sans-serif;
}

.menu ul li {
	list-style: none;
}

.menu ul li a {
	text-decoration: none;
	font-size: 24px;
	color: #111;
}

.menu ul li a:hover {
	color: #03a9f4;
}

.lang-drp{
	background-color: transparent;
	outline: none;
	border-radius: 7px;
	color: white;
	font-size: 12px;
	width: 70px;
	height: 25px;
	
}

.lang-drp option{
	color: black;
	padding: 10px 3px;

}

@media (max-width: 991px) {


	.text h2 {
		font-size: 3em;
	}

	.text h3 {
		font-size: 2em;
	}
}