* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
}

.line {
    width: 40%;
    position: relative;
    background-color: #899564;
    height: 9px;
    border-radius: 10px;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-top: 80px;
    margin-bottom: 80px;
}

.section h1 {
    color: aliceblue;

}

.imgscontainer {
    width: 100%;
    display: flex;
    gap: 25px;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;


}

.row1,
.row2,
.row3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    width: 570px;
    box-shadow: 1px solid black;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.5);

}

.row1 img,
.row2 img,
.row3 img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 1px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);

}

.row1 :nth-child(even) {
    height: 430px;
}

.row2 :nth-child(odd) {
    height: 450px;
}

.row3 :nth-child(even) {
    height: 450px;
}


.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translatey(10%);
    transition: all 1s;
}

.show {
    filter: blur(0);
    transform: translateX(0);
    opacity: 1 !important;
}

@media screen and (max-width: 1615px) {

    .row1,
    .row2,
    .row3 {
        width: 400px;
    }
}

@media screen and (max-width: 1299px) {

    .row1,
    .row2,
    .row3 {
        width: 340px;
    }
}

@media screen and (max-width: 1118px) {

    .row1,
    .row2,
    .row3 {
        width: 290px;
    }
}

@media screen and (max-width: 995px) {

    .row1,
    .row2,
    .row3 {
        width: 250px;
    }

    .row1 img,
    .row2 img,
    .row3 img {

        height: 120px;


    }

    .row1 :nth-child(even) {
        height: 200px;
    }

    .row2 :nth-child(odd) {
        height: 200px;
    }

    .row3 :nth-child(even) {
        height: 200px;
    }

    .imgscontainer {
        gap: 15px;
    }
}

@media screen and (max-width: 700px) {


    .row1,
    .row2,
    .row3 {
        width: 150px;
    }

    .row2 {
        padding-top: 5px;
        margin-top: 5px;
    }

    .row1 {
        padding-top: 5px;
        margin-top: 5px;
    }

    .row1 :nth-child(even) {
        height: 140px;
    }

    .row2 :nth-child(odd) {
        height: 140px;
    }

    .row3 :nth-child(even) {
        height: 140px;
    }

    .imgscontainer {
        gap: 15px;
    }
}

.showcaseGall {
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
    z-index: 2;
    position: relative;

}

.showcaseGall header .right-part-header {
    color: black;
}

.showcaseGall.active {
    right: 300px;
}

.showcaseGall video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    border-radius: 10px;
}

.overlayGall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    mix-blend-mode: overlay;
}

.section h1 {
    font-size: 70px;
    font-weight: bolder;
    color: black;
    text-align: center;
}

.section span {
    font-weight: 100;
}

.header-gall {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    background-image: url('../images/background.png');
    background-size: cover;
    backdrop-filter: blur(10px) !important;

}

.animate-bounce {
    animation: bounce 1.5s ease-in-out infinite;
    cursor: pointer;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    }

    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0, 0, .2, 1);
    }
}

.section button {
    padding: 15px 20px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
}

.arrowup {
    cursor: pointer;
    position: fixed;
    bottom: 40px;
    right: 60px;
    background-color: white;
    color: black;
    border: none;
    width: 42px;
    height: 42px;
    border: 10px solid white;
    border-radius: 8px;
    box-shadow: 2px 2px 5px 0px gray;
    z-index: 55;
}