@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

.about-container .showcase {
    justify-content: flex-start;
    align-items: flex-start;
}

.content {
    width: 100%;
    height: 100vh;
    color: white;
    background: url('../images/about.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content h2 {
    font-size: 70px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;

}

.content p {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;

}

.content>* {
    text-align: center;
    width: 60%;
}

.journey {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.journey-holder {
    margin: 30px 0;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 130px;
}

.slogan {
    /* font-size: 60px;
    margin: 50px 0;
    
    font-weight: bolder;
    text-align: center;
     */

    margin: 50px 0;
    /* font-size: 20px; */
    font-size: 70px;
    font-weight: bolder;
    color: black;
    text-align: center;
}

.slogan span {
    font-weight: 100;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.section-holder {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    gap: 30px;
}

.section-holder .text p {
    /* margin: 0 0 !important; */
    /* margin-bottom: 300 !important; */
    margin-top: 0 !important;
    margin-bottom: 25px !important;
    font-family: "Cairo", sans-serif;
    font-weight: 700;
}
 
.section-holder .text {
    width: 50%;
    margin-top: 0;
    font-size: 25px;
    text-align: justify;
    font-weight: 400;
    display: flex;
    font-family: "Cairo", sans-serif;
    flex-direction: column;
}

.section-holder p {
    font-weight: 400;
    font-size: 40px;
    color: black;
    margin-bottom: 0;
}

.vertical-border {
    width: 60%;
    height: 3px;
    border-radius: 15px;
    background-color: #899564;
    margin-bottom: 30px;
}

.vertical-border-header {
    /* height: 3px;
    border-radius: 15px;
    background-color: #899564;
    margin-bottom: 30px; */
    width: 40%;
    position: relative;
    background-color: #899564;
    height: 9px;
    border-radius: 10px;
    margin: auto;
}

.section-holder .img {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.section-holder .img img {
    width: 450px;
    height: 450px;
    object-fit: cover;
    border-radius: 1px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
}

.hidden {
    opacity: 0;
    filter: blur(2px);
    transform: translatey(10%);
    transition: all 1s;
}

.show {
    filter: blur(0);
    transform: translateX(0);
    opacity: 1 !important;
}

.section-holder .img a {
    background-color: #899564;
    margin: 30px 0;
    font-size: 20px;
    padding: 10px;
    text-decoration: none;
    color: white;
    border-radius: 15px;
}

.orel-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.countries {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 90px;
    margin-bottom: 60px;
}

.countries p {
    font-size: 40px;
    font-weight: 700;
}

.flags-holder {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 60px;
}

.flags-holder img {
    width: 13%;
}

.img-mobile {
    display: none;
}

.countries-img {
    width: 50%;
    margin: 0 30px;
}

.countries-img-orel {
    width: 30%;
}

@media (max-width: 1262px) {
    .about-container .showcase {
        padding: 0;
    }

    .content {
        gap: 30px;
    }

    .content h2 {
        font-size: 50px;
    }

    .content p {
        font-size: 15px;
    }

    .content h2 {
       width: 100%;
    }

    .slogan {
        font-size: 20px;
    }


    .section-holder {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section-holder .text {
        width: 100%;
        font-size: 15px;
        padding: 0;
        justify-content: center !important;
        align-items: center !important;
    }

    .section-holder .text p {
        text-align: center;
        font-size: 25px;
    }


    .section-holder .text span {
        font-size: 13px;
    }

    .section-holder .img-desk {
        display: none;
    }

    .img-mobile {
        display: block;
    }

    .section-holder .img {
        width: 100%;
    }

    .vertical-border {
        margin: 0 auto 30px auto;
    }

    .countries {
        width: 100%;
    }

    .countries p {
        text-align: center;
    }
    .journey-holder{
        gap: 80px;
    }

}