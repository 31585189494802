@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

.shop-container .showcase {
	justify-content: flex-start;
	align-items: flex-start;
	background-color: #f8f5f0;

}

.shop-content {
	width: 100%;
	height: 100vh;
	color: white;
	background: url('../images/cover.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.shop-content h2 {
	font-size: 70px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;

}

.shop-content p {
	font-size: 20px;
	font-family: 'Poppins', sans-serif;
	font-weight: 100;

}

.shop-content>* {
	text-align: center;
	width: 60%;
}

.fabrics-type-container {
	width: 100%;
	background-color: #f8f5f0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 100px;
}


.card {
	width: 420px;
	height: 340px;
	margin: 1em;
	perspective: 1500px;

	.card-content {
		position: relative;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
	}
}

.more {
	display: none;

	&:checked~.card-content {
		transform: rotateY(180deg);
	}
}

.type-1 {
	background-image: url('../images/type1.png')
}

.type-2 {
	background-image: url('../images/type2.png')
}

.type-3 {
	background-image: url('../images/type3.png')
}

.front,
.back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	border-radius: 6px;

	.inner {
		height: 100%;
		display: grid;
		padding: 1.5em;
		transform: translateZ(80px) scale(0.94);
	}
}

.front {
	background-color: #fff;
	background-size: cover;
	background-position: center center;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 6px;
		backface-visibility: hidden;
	}

	.inner {
		grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
		justify-items: center;
	}

	h2 {
		grid-row: 2;
		margin-bottom: 0.3em;
		text-transform: uppercase;
		letter-spacing: 3px;
		color: #fff;
		font-weight: 500;
		text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	}

	.rating {
		grid-row: 3;
		color: rgba(255, 255, 255, 0.8);
		font-size: 14px;
		display: flex;
		flex-flow: row nowrap;

		i {
			margin: 0 1px;
		}
	}
}

.back {
	transform: rotateY(180deg);
	background-color: #fff;
	border: 2px solid rgb(240, 240, 240);

	.inner {
		grid-template-rows: 1fr 2fr 1fr 2fr 14fr 1fr 1fr;
		grid-template-columns: repeat(4, auto);
		grid-column-gap: 0.8em;
		justify-items: center;
	}

	.info {
		position: relative;
		display: flex;
		align-items: center;
		color: black;
		grid-row: 3;

		&:not(:first-of-type):before {
			content: '';
			position: absolute;
			left: -0.9em;
			height: 18px;
			width: 1px;
			background-color: #ccc;
		}

		span {
			font-size: 0.6em;
			font-weight: 700;
		}

		i {
			&:before {
				background: linear-gradient(40deg, #355cc9, rgb(67, 138, 243));

				-webkit-text-fill-color: transparent;
				-webkit-background-clip: text;
			}

			font-size: 1.2em;
		}

		.icon {
			margin-left: 0.3em;

			span {
				display: block;
				margin-top: -0.25em;
				font-size: 0.8em;
				font-weight: 600;
				white-space: nowrap;
			}
		}
	}

	.description {
		grid-row: 5;
		grid-column: 1/-1;
		font-size: 0.86em;
		border-radius: 5px;
		font-weight: 600;
		line-height: 1.4em;
		overflow: auto;
		color: black;
		padding-right: 10px;
	}

	.location,
	.price {
		font-weight: 600;
		color: black;
		grid-row: 1;
		font-size: 0.86em;
	}

	.location {
		grid-column: 1/3;
		justify-self: left;
	}

	.price {
		grid-column: 3/-1;
		justify-self: right;
	}

	.button {
		grid-column: 1/-1;
		justify-self: center;
	}
}

.button {
	grid-row: -1;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	cursor: pointer;
	display: block;
	padding: 0 1.5em;
	height: 3em;
	line-height: 2.9em;
	min-width: 3em;
	background-color: transparent;
	border: solid 2px #fff;
	color: #fff;
	border-radius: 4px;
	text-align: center;
	left: 50%;
	backface-visibility: hidden;
	transition: 0.3s ease-in-out;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

	&:hover {
		background-color: #fff;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
		text-shadow: none;
		color: #aaff01;
	}

	&.return {
		line-height: 3em;
		color: #355cc9;
		border-color: #aaff01;
		text-shadow: none;

		&:hover {
			background-color: #aaff01;
			color: #fff;
			box-shadow: none;
		}
	}
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: lighten(#355cc9, 20%);
}

::-webkit-scrollbar-thumb:hover {
	background: #355cc9;
}

.category-section-container span {
	font-weight: 100;

}

.category-section-container h1 {
	font-size: 70px;
	font-weight: bolder;
	color: black;
	text-align: center;
}

.category-section-container {
	width: 100%;
	min-height: 50vh;
	background-color: #f8f5f0;
}

.category-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 15px;
	margin: 50px 0;

}

.category {
	width: 650px;
	height: 480px;
	background-color: black;
	background-image: url("../images/category4.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center;
	padding-top: 20px;
	padding-bottom: 20px;
	/* Center the background image */

}

.types{
	width: 100%;	
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 30px 0 ;
}

.types-bg{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 1300px;
	width: 100%;
	background-color: black;
}

.type-box{

	height: 400px;
	width: 32%;
	background-color: #899564;
	color: white;
	padding: 15px;
	transition: 0.5s;
}

.type-box:hover{
	border-radius: 30px;
}

.type-box h4{
	font-size: 25px;
	font-weight: 900;
}

.type-box p{
	text-wrap: wrap;
	margin-bottom: 10px;
	font-size: 1rem;
	font-weight: lighter;
}

.category-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	backdrop-filter: blur(4px);

}

.category-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	align-items: center;
	z-index: 10;
	padding: 15px;
}

.category-content img {

	height: 60%;
	object-fit: cover;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 15);
	border-radius: 4px;

}

.category-content h3 {
	color: white;
	font-weight: bolder;
	image-rendering: smooth;
	object-fit: fill;
}

.category-content p {
	color: white;
	font-weight: bolder;

}

.detailed {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 320px;
}

.detailed p {
	margin-bottom: 0;
	font-size: larger;
}

.detailed button {
	/* padding: 5px 7px;
	border: none;
	border-radius: 5px; */
	margin: auto;

}

.arrowup {
	cursor: pointer;
	position: fixed;
	bottom: 40px;
	right: 60px;
	background-color: white;
	color: black;
	border: none;
	width: 42px;
	height: 42px;
	border: 10px solid white;
	border-radius: 8px;
	box-shadow: 2px 2px 5px 0px gray;
	z-index: 55;
}

.piece {
	height: auto;
	min-height: 51vh;
	width: 100%;
	padding: 75px 0 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	background-color: white;
	gap: 50px;
}

.imgs {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin: 30px;
	width: 55%;
}

.small-imgs {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
	gap: 10px;
	flex-wrap: wrap;
}

.small-imgs img {
	height: 92px;
	margin: 10px 0;
	cursor: pointer;
	display: block;
	width: 60px;
	object-fit: cover;
	opacity: 0.3;
	border-radius: 5px;
	transition: all 0.3s ease;
}

.small-imgs img:hover {
	opacity: 1 !important;
	transform: scale(1.2);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

}

.types-bg .vertical-border{
	margin: 0 auto;
}


.category-section-container .vertical-border {
	width: 30%;
	height: 7px;
	border-radius: 15px;
	background-color: #899564;
	margin: 25px auto;
}

.big-img img {
	height: auto;
	max-height: 500px;
	width: auto;
	max-width: 100%;
	object-fit: cover;
	margin: 30px;
	border-radius: 10px;
}

.piece-text {
	width: auto;
	max-width: 500px;
	height: auto;
	max-height: 500px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 10px;
}

.piece-text h1 {

	font-size: 60px;
	font-weight: bolder;
	color: black;
	text-align: center;

}

.piece-text span {
	font-weight: 100;

}

.section-title {
	font-size: 45px;
	text-align: center;
}

.piece-text p {
	margin: 10px 0;
	width: auto;
	max-width: 400px;
}

.piece-text p span {
	font-size: 20px;
	font-weight: bolder;
}


.showed {
	opacity: 1 !important;
	transform: scale(1.2);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cat-text{
	font-size: 50px;
}


@media (max-width: 1262px) {

	.types{
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.type-box{
		width: 80%;
		margin: 30px;
		height: auto;
	}

	.types-bg{
		height: auto;
	}

	.cat-text{
		font-size: 40px;
		margin-top: 30px;
	}

	.fabrics-type-container {
		padding: 100px 0;
	}

	.fabrics-type-container {
		flex-direction: column;
	}

	.card {
		width: 85%;
	}

	.category-container{
		padding: 0;
	}

	.category{
		width: 85%;
	}

	.category-content img{
		max-width: 220px;
		object-fit: cover;
	}

	.detailed{
		width: auto;
	}

	.small-imgs {
		display: flex;
		width: 95%;
		align-items: center;
		justify-content: space-evenly;
		gap: 10px;
	}

	

	.imgs {
		width: auto;
		margin-left: 0;
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	.big-img {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

	}

	.big-img img {
		margin: 0;
		width: 90%;

	}

	.shop-content h2,
	.category-section-container h1 {
		font-size: 40px;
		margin: 30px 0;
	}
}

